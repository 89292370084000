<template>

    <div class="mo-reserve-wrap">

        <div style="padding:30px" v-show="RESULT_MODE == 0">
            <Survey 
                ref="Survey"
                :RESULT_MODE="this.RESULT_MODE" 
                :PAGE_MODE="this.PAGE_MODE"
                :PAGE_MODE2="'M'"
                :SURVEY_AF_DATAS="this.SURVEY_AF_DATAS"
                :SURVEY_TYPE="this.SURVEY_TYPE"
                @surveySaveBf="fnSurveySaveBf"
                @surveyEvent="fnSurveySave"
                @closeSurvey="fnClosePage"
                @surveyCmpDatas="fnSurveyCmpDatas"
                @surveyCmpAnsDatas="fnSurveyCmpAnsDatas"
            >
            </Survey>
        </div>

        <div class="reserve-form-wrap--keypad" v-show="RESULT_MODE == 1">
            <div class="mo-reserve-wrap--title" style="margin-top:50px;">참여완료</div>
            <div class="mo-reserve-wrap--container">
                <div class="mo-reserve-wrap--done-chk">
                    완료되었습니다.
                </div>
                <div class="mo-reserve-wrap--print">
                    <div v-for="(item, index) in PRINT_LIST" :key="index" class="mo-reserve-wrap--print-list">
                        <strong class="mo-reserve-wrap--print-list-title">{{ item.LIST_TITLE }}</strong>
                        <span class="mo-reserve-wrap--print-list-txt">{{ item.LIST_TEXT }}</span>
                    </div>
                </div>
            </div>
            <div class="mo-reserve-wrap--btn">
                <button type="button" @click="fnCloseBrws" style="background-color: #F1B02A;">확인</button>
            </div>
        </div>

    </div>
</template>
  
<script>
    import axios from "axios"
    import { mapState, mapGetters, mapActions } from "vuex";
    import Survey from "@/components/Survey";

    export default {
        name: "MENU_CAP0700", //name은 'MENU_' + 파일명 조합
        components: {
            Survey
        },
        metaInfo() {
            return {
                title: "참여하기",
            }
        },
        data() {
            return {
                CHOICE_DAY_TIME: '',
                cmpList: [],
                custItem: [],
                custCnt: 0,
                startTime: '', //근무시작시간
                endTime: '', //근무종료시간
                spTime: 60, //상담분리타임(분)
                dateArr: [],
                dayByTimeManArr: [],
                saveYn: true,
                SURVEY_TYPE:'TYPE-01',

                USER_ID: '',
                PAGE_TITLE: '',
                CMP_ID: '',    //캠페인ID
                CMP_NAME: '',    //캠페인nm
                BUSS_NAME: '',    //사업지nm
                CUST_ID: '',   //고객ID
                SEL_DATE: '',  //예약일자
                WEEKDAY: '',
                DATE_MIN: '',  //최소선택가능일자
                DATE_MAX: '',  //최대선택가능일자
                BTN_TIME_LIST: [],
                SEL_TIME: '',
                REV_DT: '',
                REV_VIEW_DT: '',
                SEL_SHOW: false,
                IS_REV: false,
                SAME_DATE_CHK: '',

                CMP_CNSL_ID: '',
                COMP_LINK: '',
                QUTN_ID: 0,
                BUSS_CODE: '',
                CUST_PHONE: '',
                CUST_NAME: '',
                MKTG_AGRE_YN: 'Y',
                PRSN_AGRE_YN: 'Y',
                PLUS_FRED_KEY: '',
                VIST_GUB_CD: 'CNSL_VISIT_CD_01',
                QUTN_ITEM_LIST: '',
                CHK_QUTN_RPLY: '',
                SEL_QUTN_RPLY: '',
                TXT_QUTN_RPLY: '',
                ETC_QUTN_RPLY: '',
                BF_CNSL_RSVT_DT: '',

                ACCESS_TOKEN : "",    //Arthur.Kim_20220924 알림톡토큰
                EXPIRES_IN   : "",     //Arthur.Kim_20220924 알림톡토큰유효시간

                alertMsg: {
                    error: '관리자에게 문의하세요.',
                    endCampMsg: '종료된 설문입니다.',
                    visitCormMsg: "참여하시겠습니까?",
                    afEvntChkMsg: "이미 설문에 참여하셨습니다.",
                },

                RESULT_MODE: 0, //-1,
                PAGE_MODE: 'M', //모바일
                START_DATE: '',
                END_DATE: '',
                PRESENT_DATE: '',
                PRINT_LIST: [],
                SURVEY_AF_DATAS: [],
            }
        },

        mounted() {
            $('head').append('<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0">');

            //인입 시점
            window.addEventListener('beforeunload', this.unLoadEvent);

            this.fnOneLoad();

            //카카오 인앱브라우저 벽돌현상처리
            if (window.location.hash) {
                this.fnConfirmChk();
            }
        },

        //탈출 시점
        beforeUnmount() {
            window.removeEventListener('beforeunload', this.unLoadEvent);
        },

        methods: {

            //탈출 시점 함수
            unLoadEvent: function (event) {
                event.returnValue = '';
            },

            fnConfirmChk() {
                var _ua = window.navigator.userAgent || window.navigator.vgjendor || window.opera;
                if(/iPad|iPhone|iPod/.test(_ua)) {
                    this.$store.commit("alertStore/openAlertDialog", {
                        alertDialogToggle: true,
                        msg: this.alertMsg.visitCormMsg,
                        iconClass: 'svg-chk-lg',
                        type: 'confirm',
                        callYes: this.init,
                        callNo: this.fnDontCall,
                    })
                }else{
                    if(confirm(this.alertMsg.visitCormMsg)) {
                        this.init();
                    }else{
                        this.fnDontCall();
                    }
                }

            },

            fnDontCall() {
                var _ua = window.navigator.userAgent || window.navigator.vgjendor || window.opera;
                if (_ua.toLocaleLowerCase().indexOf("kakaotalk") > -1) { // 카카오 브라우저로 실행 시킨 경우
                    window.location.href = (/iPad|iPhone|iPod/.test(_ua)) ? "kakaoweb://closeBrowser" : "kakaotalk://inappbrowser/close"
                } else {
                    window.open('about:blank', '_self').self.close();
                }
                this.fnNoCall();
            },

            fnNoCall() {
                this.$store.commit("alertStore/openAlertDialog", {
                    alertDialogToggle: false,
                });
            },

            fnOneLoad() {
                window.onload = function () {
                    if (!window.location.hash) {
                        window.location = window.location + '#loaded';
                        window.location.reload();
                    }
                }
            },

            async init() {
                this.mixin_alarmTalk_token();

                //설문 호출
                this.$refs.Survey.fnInit();

                this.fnNoCall();

                //파라미터 수신
                if (!this.mixin_isEmpty(this.$route.query.cmp_id)) {
                    this.CMP_ID = this.$route.query.cmp_id.replace(/[\"\']/gi, '');   //캠페인ID
                }
                if (!this.mixin_isEmpty(this.$route.query.buss_code)) {
                    this.BUSS_CODE = this.$route.query.buss_code.replace(/[\"\']/gi, '');   //사업지 코드
                }
                if (!this.mixin_isEmpty(this.$route.query.mktg_agre_yn)) {
                    this.MKTG_AGRE_YN = this.$route.query.mktg_agre_yn.replace(/[\"\']/gi, ''); //마케팅 동의 여부
                }
                if (!this.mixin_isEmpty(this.$route.query.prsn_agre_yn)) {
                    this.PRSN_AGRE_YN = this.$route.query.prsn_agre_yn.replace(/[\"\']/gi, ''); //개인정보 수집 동의 여부
                }
                if (!this.mixin_isEmpty(this.$route.query.cust_phone)) {
                    this.CUST_PHONE = this.$route.query.cust_phone.replace(/[\"\']/gi, ''); //고객 핸드폰번호
                }
                if (!this.mixin_isEmpty(this.$route.query.plus_fred_key)) {
                    this.PLUS_FRED_KEY = this.$route.query.plus_fred_key.replace(/[\"\']/gi, '');  //플러스 친구 사용키
                }

                this.USER_ID = this.user_id;
            },

            getWeekDay(dateDay) {
                const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토'];
                let i = new Date(dateDay).getDay(dateDay);
                return daysOfWeek[i];
            },
            
            callNo() {
                this.$store.commit("alertStore/openAlertDialog", {
                    alertDialogToggle: false,
                });
            },

            sendEventApi(resBotData, resCnslId, resInfo) {

                let eventName = "eventnew";
                let plusfriendUserKey = this.PLUS_FRED_KEY;
                let apiKey = resBotData[0].BLOCK_API_KEY;
                let botId = resBotData[0].BOT_ID;
                let cmpCnslId = resCnslId[0].CMP_CNSL_ID;
                this.CMP_CNSL_ID = cmpCnslId
                let custPhone = this.CUST_PHONE;
                let bussCode = this.BUSS_CODE;

                $.ajax({
                    url: "http://admin.opentohome.com:7443/skill/adminEventApi",
                    dataType: "json",
                    type: "POST",
                    ContentType: "text/plain",
                    crossDomain: true,

                    data: {
                        eventName: eventName,
                        plusfriendUserKey: plusfriendUserKey,
                        apiKey: apiKey,
                        botId: botId,
                        cmpCnslId: cmpCnslId,
                        custPhone: custPhone,
                        bussCode: bussCode,
                        resInfo: resInfo
                    },
                    success: function (data) {
                        console.log('EventApi SUCC');
                    },
                    error: function (request, status, error) {
                        console.log('adminEventApi ERR : ', error);
                    },
                })

            },

            fnClosePage() {
                setTimeout(() => this.fnCloseBrws(), 1500);
            },

            fnCloseBrws() {
                if(this.mixin_isEmpty(this.COMP_LINK) || !this.mixin_valid_url(this.COMP_LINK)) {
                    var _ua = window.navigator.userAgent || window.navigator.vgjendor || window.opera;
                    if (_ua.toLocaleLowerCase().indexOf("kakaotalk") > -1) {
                        window.location.href = (/iPad|iPhone|iPod/.test(_ua)) ? "kakaoweb://closeBrowser" : "kakaotalk://inappbrowser/close";
                    } else {
                        window.open("about:blank", "_self").close();
                    }
                } else {
                    window.location.href = this.COMP_LINK;
                    window.location.replace(this.COMP_LINK);
                    window.open(this.COMP_LINK);
                }
            },

            fnSurveySaveBf(num) {
                this.PRINT_LIST = [
                    {
                        LIST_TITLE: '내용',
                        LIST_TEXT: this.CMP_NAME,
                    },
                    {
                        LIST_TITLE: '기간',
                        LIST_TEXT: this.START_DATE + ' ~ ' + this.END_DATE
                    },
                ];

                this.RESULT_MODE=num;
            },

            async fnSurveySave(surveyAfSave) {
                this.SURVEY_AF_DATAS = surveyAfSave;

                //사전예약 및 설문데이터 저장 테이블 key sett
                if (this.SURVEY_AF_DATAS.length > 0) {
                    this.SURVEY_AF_DATAS.forEach((element, i) => {
                        this.CMP_CNSL_ID = element.CMP_CNSL_ID;
                        if(!this.mixin_isEmpty(element.COMP_URL)) {
                            this.COMP_LINK = element.COMP_URL;
                        }
                    });
                }

                //알림톡 발송
                await this.sendMessage();
            },

            async sendMessage() {
                let requestData = {
                    headers: {},
                    sendData: {},
                };

                let token;

                //header 세팅
                requestData.headers["URL"] = "/api/hlw/campaign/alarmTalk-msgformat/manage/list";
                requestData.headers["SERVICE"] = "hlw.campaign.alarmTalk-msgformat.manage";
                requestData.headers["METHOD"] = "list";
                requestData.headers["ASYNC"] = false;

                //sendData 초기화
                requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;              //사업지 아이디
                requestData.sendData["CMP_ID"] = this.CMP_ID;                   //캠페인 아이디
                requestData.sendData["CUST_PHONE"] = this.CUST_PHONE;                   
                requestData.sendData["CUST_USER_KEY"] = this.PLUS_FRED_KEY;                   
                requestData.sendData["MSG_TMPL_TYPE"] = "SURVEY_NOTI_TALK";           //메세지 속성 코드(신청완료) 설문 알림톡
                
                let response = await this.common_postCall(requestData);

                if (!response.HEADER.ERROR_FLAG) {

                    let data = response.DATA;
                    let data_btn = response.DATA_BTN;
                    let custData = response.CUST_DATA;

                    if (data != null && data.length > 0) {

                        let msgFormat = data[0].MSG_CONT;  //메세지내용

                        const todayDate=new Date();
                        let yyyy=todayDate.getFullYear();
                        let mm=todayDate.getMonth()+1>9?(todayDate.getMonth()+1):'0'+(todayDate.getMonth()+1);
                        let dd=todayDate.getDate()>9?todayDate.getDate():'0'+todayDate.getDate();
                        let hour=todayDate.getHours()>9?todayDate.getHours():'0'+todayDate.getHours();
                        let minute=todayDate.getMinutes()>9?todayDate.getMinutes():'0'+todayDate.getMinutes();

                        const todayTime=yyyy+'-'+mm+'-'+dd+' '+hour+':'+minute;

                        msgFormat = msgFormat.replace(/#{사업지명}/g, data[0].BUSS_NAME)
                        .replace(/#{고객명}/g, custData[0].CUST_NAME)
                        .replace(/#{상담신청일시}/g, todayTime)
                        .replace(/#{설문제출일시}/g, todayTime);

                        // let sendMsgFormat = this.decode(msgFormat);

                        let setButtons = [];
                        let setquickButtons = [];

                        let dataBtnLen = (typeof data_btn != undefined && typeof data_btn != "undefined") ? data_btn.length : 0;
                        let i = 0;

                        if (dataBtnLen > 0) {

                            for (; i < dataBtnLen; i++) {
                                if (data_btn[i].BTN_TYPE == "MSG_BTN_TYPE_CO") {
                                    //일반 버튼(button)
                                    setButtons.push({
                                        name: data_btn[i].BTN_NAME,
                                        type: data_btn[i].BTN_LINK_TYPE,
                                        url_mobile: data_btn[i].BTN_LINK_URL
                                    });
                                } else {
                                    //바로가기 버튼(Quick_reply)
                                    setquickButtons.push({
                                        name: data_btn[i].BTN_NAME,
                                        type: data_btn[i].BTN_LINK_TYPE,
                                        url_mobile: data_btn[i].BTN_LINK_URL
                                    });
                                }
                            }
                        }

                        const sendButton = { "button": setButtons };

                        let requestAlarmTalkData = {
                            headers: {},
                            sendData: {},
                        };

                        let buttonToString = `'${JSON.stringify(sendButton)}'`;
                        requestAlarmTalkData.headers["URL"] = "/api/hlw/campaign/alarmTalk-postmsg/manage/post";
                        requestAlarmTalkData.headers["SERVICE"] = "hlw.campaign.alarmTalk-postmsg.manage";
                        requestAlarmTalkData.headers["METHOD"] = "post";
                        requestAlarmTalkData.headers["ASYNC"] = false;

                        requestAlarmTalkData.sendData["CMP_ID"] = this.CMP_ID;
                        requestAlarmTalkData.sendData["TRAN_SENDER_KEY"] = data[0].SNDR_KEY;
                        requestAlarmTalkData.sendData["TRAN_TMPL_CD"] = data[0].MSG_TMPL_CODE;
                        requestAlarmTalkData.sendData["TRAN_PHONE"] = this.CUST_PHONE.replaceAll("-", "");
                        requestAlarmTalkData.sendData["TRAN_CALLBACK"] = data[0].SNDR_PHONE.replaceAll("-", "");
                        requestAlarmTalkData.sendData["TRAN_MSG"] = msgFormat;
                        requestAlarmTalkData.sendData["TRAN_REPLACE_MSG"] = msgFormat;
                        if(sendButton["button"].length != 0) requestAlarmTalkData.sendData["TRAN_BUTTON"] = this.encode(buttonToString);

                        let alarmTalkResponse = await this.common_postCall(requestAlarmTalkData);

                        if (alarmTalkResponse.HEADER.ERROR_FLAG) {
                            this.common_alert("관리자에게 문의해주세요.", "error");
                        }
                    }
                }
            },

            fnBackSurvey() {
                this.RESULT_MODE=0;
            },

            fnSurveyCmpDatas(datas) {
                if(this.mixin_isEmpty(datas.QUTN_ID)) {
                    this.common_alert(this.alertMsg.endCampMsg, "chk");
                    this.fnClosePage();
                    return;
                }

                this.BUSS_NAME=datas.BUSS_NAME;
                this.START_DATE=datas.CMP_STDT;
                this.END_DATE=datas.CMP_ENDT;
                this.PRESENT_DATE=datas.CMP_ENDT;
                this.CMP_NAME=datas.CMP_NAME;
            },

            fnSurveyCmpAnsDatas(surveyCmpAnsDatas) {
                if(!this.mixin_isEmpty(surveyCmpAnsDatas)) {
                    //이미 설문에 참여한 경우 창 닫기
                    this.common_alert(this.alertMsg.afEvntChkMsg, "chk");
                    this.fnClosePage();
                    return;
                }else{
                    this.RESULT_MODE=0;
                }
            },

            decode(strText) {

                //값존재유무 체크
                if (strText === "" || strText == null) {
                    return strText;
                }

                strText = strText.toString();

                //문자열 길이가 4이상일 경우만 처리
                if (strText.length <= 3) {
                    return strText;
                }

                //순서바뀌면 안나옴
                strText = strText.replaceAll("&lt;", "<");
                strText = strText.replaceAll("&gt;", ">");
                strText = strText.replaceAll("&amp;", "&");
                strText = strText.replaceAll("&quot;", '"');
                strText = strText.replaceAll("&apos;", "'");
                strText = strText.replaceAll("<br>", "\r");
                strText = strText.replaceAll("<p>", "\n");
                strText = strText.replaceAll("&#91;", "[");
                strText = strText.replaceAll("&#93;", "]");
                strText = strText.replaceAll("&#40;", "(");
                strText = strText.replaceAll("&#41;", ")");

                return strText;
            },

            encode(strText) {
                // 값 존재 유무 체크
                if (strText === "" || strText == null) {
                    return strText;
                }

                strText = strText.toString();

                // 문자열 길이가 4 이상일 경우만 처리
                if (strText.length <= 3) {
                    return strText;
                }

                // 문자열을 HTML 엔티티로 인코딩
                strText = strText.replaceAll("&", "&amp;");
                strText = strText.replaceAll("<", "&lt;");
                strText = strText.replaceAll(">", "&gt;");
                strText = strText.replaceAll('"', "&quot;");
                strText = strText.replaceAll("'", "&apos;");
                strText = strText.replaceAll("\r", "<br>");
                strText = strText.replaceAll("\n", "<p>");
                strText = strText.replaceAll("\[", "&#91;");
                strText = strText.replaceAll("\]", "&#93;");
                strText = strText.replaceAll("\(", "&#40;");
                strText = strText.replaceAll("\)", "&#41;");

                return strText;
            }
        },

        computed: {
            ...mapState({
                stateToken: "authStore/token",
                accessToken: "authStore/GE_ACCESS_TOKEN",
                expiresIn: "authStore/GE_EXPIRES_IN",
            }),
            initHeaders() {
                return {
                    SERVICE: 'hlw.campaign.manage',
                    METHOD: "",
                    TYPE: 'BIZ_SERVICE',
                };
            },
            paramData() {
                return {
                };
            }
        },
    };
</script>